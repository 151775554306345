<template>
  <div style="width: 620px; margin: 0 auto">
    <template v-if="ordersData.length > 0">
      <v-checkbox
          label="QR Code"
          v-model="qrIsActive"
          class="hide-on-print"
        />
      <template v-for="item in ordersData">
        <BouquetBlank
          :orderData="item"
          :qrIsActive = "qrIsActive"
          :key="`bouquet-blank-${item.id}`"
        />
      </template>

      <v-btn
        color="primary"
        dark
        class="mb-4 print-btn"
        @click.prevent="printPage()"
      >Распечатать</v-btn>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import BouquetBlank from '@/components/BouquetBlank.vue';

export default {
  name: 'bouquet-receipt',
  components: {
    BouquetBlank,
  },
  data() {
    return {
      ids: 0,
      ordersData: [],
      qrIsActive: false,
    };
  },
  methods: {
    getItems() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bouquets-multi-print`;

      const props = {
        bouquet_ids: this.ids,
      };

      axios
        .post(url, props)
        .then((response) => {
          this.ordersData = response.data;
          console.log("this.ordersData", this.ordersData)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    printPage() {
      window.print();
    },
  },
  mounted() {
    this.ids = JSON.parse(this.$route.params.id);
    this.getItems();
  },
};
</script>
<style>
/* Hide elements with the class 'hide-on-print' when printing */
@media print {
  .hide-on-print {
    display: none !important;
  }
}
</style>
