<template>
  <div>
    <v-alert
        :value="createdSuccess"
        type="success"
        class="my-0"
      >Оплата изменена</v-alert>
      <v-alert :value="errorMessage" type="error" class="my-0">
        {{errorAlert}}
      </v-alert>
    <div style="display: flex">
      <header style="display: flex; flex-flow: column; align-items: center; width: 300px">
        <template v-if="!qrIsActive">
          <div style="text-transform: uppercase; font-size: 18px;">Товарный чек</div>
          <div contenteditable>{{ date }}</div>
        </template>
          
        <template v-if="qrIsActive">
          <div style="font-weight: bold; font-size:60px;"><span >{{ orderData.total_cost }}</span> руб</div>
          <QrcodeGenerator :qrValue="generateQrValue()" @qr-scanned="vtSubmitForm" />
        </template>
      </header>

      <ul
        style="display: flex; flex-flow: column; width: 320px; margin: 0 0 5px 0; font-size: 14px"
      >
        <li style="display: flex; font-size: 12px; font-weight: 600">
          <template v-if="qrIsActive">
            <div style="width: calc(320px / 2); text-align: end"></div>
            <div contenteditable style="width: calc(320px / 2)">
              <div style="text-align: center">
                {{ date }}
              </div>
            </div>
          </template>
        </li>
        <li style="display: flex; font-size: 12px; font-weight: 600">
          <div style="width: calc(320px / 2); text-align: end">Флорист</div>
          <div style="width: calc(320px / 2)">
            <div style="text-align: center">
              <template v-if="orderData.florist">
                {{ orderData.florist.name }}
              </template>
            </div>
          </div>
        </li>
        <li style="display: flex; font-size: 12px; font-weight: 600">
          <div style="width: calc(320px / 2); text-align: end">Получатель</div>
          <div style="width: calc(320px / 2); text-align: center">{{ orderData.client.name }}</div>
        </li>
        <li style="display: flex; font-size: 16px; font-weight: 600">
          <div style="width: calc(320px / 2); text-align: end">№</div>
          <div style="width: calc(320px / 2); text-align: center;">
            {{ orderData.id }}
          </div>
        </li>
        <li style="display: flex; font-size: 16px; font-weight: 600">
          <div style="width: calc(320px / 2); text-align: end">% ставка на оформление</div>
          <div style="width: calc(320px / 2); text-align: center;">
            {{ orderData.decor_percent }}
          </div>
        </li>
        <li style="display: flex; font-size: 16px; font-weight: 600">
          <div style="width: calc(320px / 2); text-align: end">Сумма товара</div>
          <div style="width: calc(320px / 2); text-align: center">
            {{ goodsSum }}
          </div>
        </li>
        <li style="display: flex; font-size: 16px; font-weight: 600">
          <div style="width: calc(320px / 2); text-align: end">Стоимость доставки</div>
          <div style="width: calc(320px / 2); text-align: center">
            {{ orderData.delivery_cost }}
          </div>
        </li>
        <li style="display: flex; font-size: 16px; font-weight: 600">
          <div style="width: calc(320px / 2); text-align: end">Стоимость оформления</div>
          <div style="width: calc(320px / 2); text-align: center">
            {{ orderData.decor_cost + orderData.sum_decor_additional }}
          </div>
        </li>
        <li style="display: flex; font-size: 16px; font-weight: 600">
          <div style="width: calc(320px / 2); text-align: end">% ставка на скидку</div>
          <div style="width: calc(320px / 2); text-align: center;">
            {{ orderData.sale_percent }}
          </div>
        </li>
        <li style="display: flex; font-size: 12px;">
          <div style="width: calc(320px / 2); text-align: end"> Сумма скидки</div>
          <div style="width: calc(320px / 2); text-align: center;">
            {{ orderData.sum_sale }}
          </div>
        </li>
        <li style="display: flex ; font-size: 16px">
          <div style="width: calc(320px / 2); text-align: end">Коплате</div>
          <div style="width: calc(320px / 2); text-align: center">{{ orderData.total_cost }}</div>
        </li>
      </ul>
    </div>

    <table style="width: 620px; border-collapse: collapse; padding: 2px;">
      <tr>
        <th style="border: 1px solid black">Тип</th>
        <th style="border: 1px solid black">Наименование</th>
        <th style="border: 1px solid black">Магазин</th>
        <th style="border: 1px solid black">цена без оформ.</th>
        <th style="border: 1px solid black">цена с оформ.</th>
        <th style="border: 1px solid black">кол-во</th>
      </tr>
      <template v-for="(item, index) in orderData.goods">
        <tr style="padding-left: 5px;" :key="index">
          <td style="border: 1px solid black; padding-left: 5px;"></td>
          <td style="border: 1px solid black; padding-left: 5px;">{{ item.good.name }}</td>
          <td style="border: 1px solid black; padding-left: 5px;">{{ orderData.store.name }}</td>
          <td style="border: 1px solid black; padding-left: 5px;">{{ item.original_price }}</td>
          <td style="border: 1px solid black; padding-left: 5px;">
            {{ item.original_price + (item.original_price * (orderData.decor_percent / 100)) }}
          </td>
          <td style="border: 1px solid black; padding-left: 5px;">{{ item.count }}</td>
        </tr>
      </template>
    </table>

    <br>
    <div class="pay-btn">
      <v-btn
        dark
        class="mb-4 print-btn"
        :disabled="hasVtPaymentType"
        :color="hasVtPaymentType ? 'grey' : 'info'"
        @click="!hasVtPaymentType ? vtSubmitForm() : null"
        :loading="btnLoad"
      >VT Payment</v-btn>
      <!-- <v-btn
        color="info"
        dark
        class="mb-4 print-btn"
        
      >ALFA</v-btn> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import format from "date-fns/format";
import { ru } from "date-fns/locale";
import QrcodeGenerator from './QrcodeGenerator.vue';

export default {
  name: 'BouquetBlank',
  data() {
      return {
        vtItem: {
          bouquet_id: null,
          client_id: null,
          store_id: null,
          amount: null,
          payment_type_id: null,
        },
        createdSuccess: false,
        errorMessage: false,
        errorAlert: '',
        btnLoad: false,
        // qr_is_active: false,
        ip_address: '',
      };
    },
  components: {
    QrcodeGenerator
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    qrIsActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    hasVtPaymentType() {
      // Check if any payment has payment_type_id not equal to 16
      return this.orderData.payments.some(payment => payment.payment_type_id == 16);
    },
    dateFormat() {
      return format(new Date(this.orderData.created_at), 'dd.MM.yyyy HH:mm:ss', { locale: ru });
    },
    date() {
      return this.dateFormat.split(' ')[0];
    },
    time() {
      return this.dateFormat.split(' ')[1];
    },
    goodsSum() {
      return this.orderData.goods.reduce((sum, item) => sum + (item.original_price * item.count), 0);
    },
  },
  methods: {
    vtSubmitForm() {
      if(!this.hasVtPaymentType){ // if there is no VT payment (payment_type_id is not 16)
        this.btnLoad = true;
        this.vtItem.bouquet_id = this.orderData.id;
        this.vtItem.client_id = this.orderData.client_id;
        this.vtItem.amount = this.orderData.total_cost;
        this.vtItem.store_id = this.orderData.store_id;
        this.vtItem.payment_type_id = 16; 

        console.log(this.vtItem);
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-vendor`;

        axios
          .post(url, this.vtItem)
          .then(() => {
            this.errorMessage = false;
            this.createdSuccess = true;
            this.hasVtPaymentType = true;
            this.btnLoad = false;
          })
          .catch((error) => {
            this.errorMessage = true;
            if (error.response) {
              if (error.response.status === 400 || error.response.status === 500) {
                  if (error.response.data && error.response.data.error) {
                    this.errorAlert = error.response.data.error;
                    this.btnLoad = false;
                  }
              }
            }
          });
      }
      
    },
    generateQrValue() {
      // return `${this.ip_address}/#/print/bouquet/receipt/[${this.orderData.id}]/`;
      // return `${this.ip_address}/vt?` +
      //        `client_id=${this.orderData.client_id}&` +
      //        `bouquet_id=${this.orderData.id}&` +
      //        `amount=${this.orderData.total_cost}&` +
      //        `store_id=${this.orderData.store_id}`;
      // return `curl -X POST "${this.ip_address}/payments-vendor" \
      //           -H "accept: application/json" \
      //           -H "x-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NjU1ODg3MTYsImlhdCI6MTczNDA1MjcxNiwidXNlciI6ImFkbWluIn0.Qh-Hjpjf0dtyBUqxpt_qKQC5_pB-GvvinbyH5HI7_fM" \
      //           -H "Content-Type: application/json" \
      //           -d "{ \\"client_id\\": ${this.orderData.client_id}, \\"bouquet_id\\": ${this.orderData.id}, \\"amount\\": ${this.orderData.total_cost}, \\"store_id\\": ${this.orderData.store_id} }"`;
      // return  `curl -X POST "${this.ip_address}/payments-vendor" -H "accept: application/json" -H "x-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NjU1ODg3MTYsImlhdCI6MTczNDA1MjcxNiwidXNlciI6ImFkbWluIn0.Qh-Hjpjf0dtyBUqxpt_qKQC5_pB-GvvinbyH5HI7_fM" -H "Content-Type: application/json" -d "{ \\"amount\\": ${this.orderData.total_cost}, \\"client_id\\": ${this.orderData.client_id}, \\"payment_type_id\\": 16, \\"store_id\\": ${this.orderData.store_id} }"`;
      return  `{ \\"amount\\": ${this.orderData.total_cost}, \\"client_id\\": ${this.orderData.client_id}, \\"payment_type_id\\": 16, \\"boutquet_id\\": ${this.orderData.id}, \\"store_id\\": ${this.orderData.store_id} }`;
      // return JSON.stringify({
      //     client_id: this.orderData.client_id,
      //     bouquet_id: this.orderData.id,
      //     amount: this.orderData.total_cost,
      //     store_id: this.orderData.store_id,
      //     payment_type_id: 16,
      // });             
    },
    getSettingRemote(id) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/setting-remote/${id}`;
      axios
          .get(url)
          .then((response) => {
            const item = response.data;
            this.ip_address = item.ip_address;
            // this.qr_is_active = item.qr_is_active;
          })
          .catch((error) => {
          console.log(error);
          });
    },
    // printPage() {
    //   window.print();
    // },
  },
  mounted() {
    this.getSettingRemote(1)  
  },
};
</script>
<style>
  .pay-btn {
    display: flex;
    justify-content: end;
  }
</style>
